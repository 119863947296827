@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../public/assets/fonts/font.css";

html {
  background: #fff !important;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2em;
  font-weight: bold;
}

.text-lg {
  line-height: 1.2em;
}

.sms-icon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: rgb(24, 190, 179);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px auto;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: bold;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.lg\:w-auto {
  width: auto;
}
.text-black {
  color: #0b0d18 !important;
}

.custom-tooltip.invisible {
  visibility: hidden !important;
}

.popover-container {
  position: fixed !important;
  z-index: 9999;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  width: auto;
  display: block;
  top: 20px !important;
  max-width: 300px;
}

.popover-container::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.form-textarea {
  border-color: rgba(0, 0, 0, 0.25);
}

.popover-container .text-black p {
  color: #000;
}

.sm\:grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.md\:flex-row {
  flex-direction: row;
}

.md\:table-cell {
  display: table-cell;
}

.flex-col {
  flex-direction: column;
}

.sm\:gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}

.mb-7 {
  margin-bottom: 0;
}

.cursor-auto {
  cursor: auto !important;
}

button.copy-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  background: #e8f9f8;
  padding: 6px 10px;
  color: #18beb3;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
}

button.copy-link:focus {
  outline: none;
}

button.copy-link .truncate {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 767px) {
  button.copy-link .truncate {
    width: calc(100% - 50px);
  }
}

button.copy-link span {
  color: #202436;
  padding-left: 7px;
}

button.copy-link span.copied {
  color: #05dd05 !important;
}

body {
  overflow-x: hidden;
}

.overflow-hidden-imp {
  overflow-y: hidden !important;
}

img.__Logo {
  width: auto;
  position: relative;
  top: 0;
  left: 0;
}

img.__Logo.application-close {
  position: absolute;
  left: auto;
  top: 30px;
}

img.__Logo.time-to-raise {
  width: 120px;
}

.refer-image {
  width: 80px;
  height: 80px;
}

/* @TODO: update CustomModal component in order to use custom width */
.about-to-close-application .modal-popup {
  width: 740px !important;
  max-width: 100%;
}

.already-have-account .modal-popup {
  width: 700px !important;
  padding: 30px !important;
}

.already-register .modal-popup {
  width: 400px !important;
  padding: 30px !important;
}
.__search::placeholder {
  color: #6b7280 !important;
  opacity: 1 !important;
}
.__search {
  color: #333 !important;
}
.traction-dropdown {
  width: 130px;
}
.__edit__icon {
  position: absolute;
  top: 60%;
  right: -8px;
}
.height-set {
  height: 160px;
}

.pagination a {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  height: 35px;
  width: 35px;
}

.pagination {
  display: flex;
  float: right;
  margin: 12px 0 !important;
}

.pagination li:last-child a {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination li:first-child a {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination li.disabled a {
  pointer-events: none;
  color: #6c757d;
}

.pagination li.active a {
  background: #007bff;
  color: #fff;
}

button:focus {
  outline: none;
}

input:focus-visible {
  /* border: none !important; */
  outline: none !important;
}

.investor-contact-modal {
  background: url("../public/assets/images/modal-background.jpg");
  background-repeat: no-repeat;
}

#message {
  min-height: 80px;
  max-height: 80px;
  height: 80px;
}
button:focus-visible {
  outline: 2px solid black !important;
}
a:focus-visible {
  outline: 2px solid black !important;
}
.pac-target-input::placeholder {
  opacity: 1;
}
.select-lang:focus-visible {
  outline: 2px solid black !important;
}
.__custom {
  min-width: 180px;
}
input::placeholder {
  --placeholder-opacity: 1;
  color: #6b7280 !important;
  opacity: 1;
}
input {
  color: #000;
  font: inherit;
}
.__res_icon {
  position: absolute;
  right: 10px;
  box-shadow: 0px 0px 10px #00000026;
  border-radius: 50%;
}
/* .container {
    max-width: 100% !important;
} */
.__close {
  background: #000;
  width: 40px;
  height: 40px;
  color: #fff;
  border-radius: 50%;
}
.__button .border-none {
  border: none;
  box-shadow: none;
  color: #6b7280;
}
.__button button.mr-0 {
  margin-right: 0px;
}
.chat-icon {
  display: block;
}
.icon-set .svg-icon svg {
  margin-left: 4px;
  margin-right: -4px;
}
.icon__ svg {
  width: 17px !important;
}

.tooltip-wrap {
  display: flex;
}
.tooltip-text {
  position: absolute;
  font-size: 13px;
  min-width: 270px;
  max-width: 370px;
  line-height: 18px;
  z-index: 9;
  background: #fff;
  padding: 10px;
  box-shadow: 1px 1px 5px #0000005c;
  border-radius: 6px;
  left: 50px;
  transform: translate(15px, 15px);
  display: none;
  transition: 0.5s;
  font-weight: normal;
}
.tooltip:hover .tooltip-text {
  display: block;
  transform: translate(0);
  transition: 0.5s;
}
.tooltip {
  position: relative;
  margin-left: 10px;
  margin-top: 10px;
}
.target .tooltip {
  margin-top: 4px;
  height: 20px;
}
.target.tooltip-wrap .tooltip svg {
  height: auto;
}
.target.tooltip-wrap .tooltip-text {
  right: 0;
  top: 30px;
  line-height: 18px;
  left: initial;
  font-size: 12px;
}
.tooltip svg {
  height: 43px;
}
.tooltip-wrap {
  display: flex;
  width: 100%;
}
.padding-none {
  padding: 0px !important;
}
.__copy_link {
  bottom: -25px;
}
.__banners {
  height: 100%;
  text-align: center;
  flex-direction: column;
  color: #fff;
  align-items: center;
  justify-content: center;
}
.__banners p {
  color: #fff;
}
.__banners img {
  max-width: 80%;
  margin-bottom: 20px;
}
.__custom_input input {
  display: none;
}
.__custom_input label {
  padding: 10px 15px;
  border-radius: 50px;
  border: 1px solid #7070704d;
  margin-left: 0px;
}
.__custom_input input:checked + label {
  background-color: #ff8060;
  color: #fff !important;
  border-color: #ff8060;
}
.__custom_input input:checked + label span {
  color: #fff !important;
}
.__react_component_tooltip {
  max-width: 80%;
  line-height: 19px;
}
.__img {
  text-align: center;
  width: 100%;
  max-width: 300px;
  margin-right: 40px;
}
.__img img {
  width: 100%;
}
._traction .px-2.svg-icon {
  margin-top: 1px !important;
}
.company-dropdown .border-gray-300 {
  border-color: #bfbfbf;
  border-radius: 5px;
  height: 46px;
  margin-top: 0;
}
@media screen and (max-width: 640px) {
  .__none__mrgn .w-full {
    margin: 0px;
  }
}
@media screen and (min-width: 768px) {
  .md\:w-1\/6 {
    width: 16.666667%;
  }
  .md\:w-1\/2 {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .truncate.font-medium.__hover__orange:hover {
    color: #ff8060;
    transition: 0.5s;
  }
  .traction-dropdown {
    width: 200px;
  }
  .__copy_link {
    top: -10px;
  }
  .__gris__chnaged {
    flex-direction: column;
  }
  .banner-text {
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
  }
  .tooltip-wrap {
    font-size: 18px;
  }

  .tooltip-text {
    min-width: 285px;
    right: 0px !important;
    left: 0px !important;
    font-size: 16px;
  }
  .tooltip {
    margin-top: 0px;
    position: inherit;
  }
  .__icon {
    position: absolute;
    right: -10px !important;
    top: 12px;
  }
  .__Logo {
    left: 15px;
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    height: 55px;
    display: flex;
    align-items: center;
  }
  /* .cursor-pointer.ml-2 {
        margin-left: 0;
    } */
  .cursor-pointer.ml-3 {
    margin-left: 10px;
  }
  .grow {
    padding-right: 8px;
  }
  .navbar .flex-shrink-0 svg {
    width: 95px;
  }
  .navbar #user-menu img {
    width: 30px;
    height: 30px;
  }
  .__icon {
    position: absolute;
    right: -30px;
    top: 5px;
  }
  .__bottom_res {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .tooltip {
    position: inherit;
  }
  .__img {
    margin: 0 auto;
  }
  .__img img {
    float: none !important;
    margin: 0 auto;
  }
  .video-modal {
    margin-top: 70px !important;
  }
  .instruction-section img {
    right: 10px !important;
  }
}

@media screen and (min-width: 1536px) {
  .container {
    max-width: 1280px;
  }
}

@media screen and (max-width: 1280px) {
  .container {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .lg\:w-1\/2 {
    width: 50%;
  }
  .lg\:justify-center {
    justify-content: center;
  }
}

/* Works on Firefox */
* {
  -webkit-font-smoothing: antialiased;
  scrollbar-width: auto;
  scrollbar-color: #18beb3 #f5f5f5;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: #18beb3;
  border-radius: 20px;
  border: 3px solid #f5f5f5;
}

.invisible-scroll::-webkit-scrollbar,
.invisible-scroll *::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.deep-dive-table tr:nth-child(odd) {
  background: #ffffff;
}

.deep-dive-table {
  cursor: auto;
}

html,
body,
#root {
  height: 100%;
}

p {
  color: rgb(107, 114, 128);
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

h2 {
  line-height: 1.2em;
  font-weight: bold;
}

body {
  font-family: CircularStd, sans-serif;
}

table:not(.no-hover) tr:hover td {
  background: rgb(250, 250, 251);
  cursor: pointer;
}

/* @TODO: remove once UI components allows us more flexible config */
.dealflow-multi-select button {
  padding: 4px 24px 4px 8px;
  border-color: rgb(229 231 235);
}

.dealflow-multi-select .__custom {
  z-index: 100;
}

.status-select button {
  padding: 2px 4px;
}

html.dark body {
  background: #141415;
}

.patter-right {
  background-position: right -180px top 0px !important;
}

.patter-left {
  background-position: left -180px top 50px !important;
}

.unscore {
  display: inline-block;
  padding-bottom: 2px;
  padding-top: 2px;
  background-image: linear-gradient(#18beb3 0 0);
  background-position: 0 100%;
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition: background-size 0.5s, background-position 0s 0.5s;
}

.unscore:hover {
  background-position: 100% 100%;
  background-size: 100% 2px;
}

html .hide-on-small-height {
  display: none;
}

@media screen and (min-height: 720px) and (min-width: 768px) {
  html .hide-on-small-height {
    display: initial;
  }
}

button .preview-btn {
  margin-right: 10px;
  border-color: #18beb3;
  color: #18beb3;
}

button .preview-btn:hover,
button .preview-btn:focus {
  background: #fff;
}

div.boards ul li {
  list-style: disc;
  list-style: outside;
  margin-left: 20px;
  color: rgba(115, 122, 135);
}

div.boards ol li {
  list-style-type: decimal;
  color: rgba(115, 122, 135);
  margin-left: 25px;
}

div.boards p {
  color: rgba(115, 122, 135);
}

.public-DraftStyleDefault-block {
  margin: 0px;
}

div.dark-board p {
  color: #f4f3ef;
}

div.dark-board ul li {
  color: #f4f3ef;
}

div.dark-board ol li {
  color: #f4f3ef;
}

.flipCard.unfold {
  top: 50%;
  align-items: flex-start;
  transform-origin: 50% 0%;
  transform: rotateX(180deg);
  background-color: #18beb3;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.65);
}

.flipCard.unfold span {
  transform: translateY(-50%);
}

.flipCard.fold {
  top: 0%;
  align-items: flex-end;
  transform-origin: 50% 100%;
  transform: rotateX(0deg);
  background-color: #18beb3;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.flipCard.fold span {
  transform: translateY(50%);
}
/* Animation mixin */
@keyframes animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fold {
  transform-style: preserve-3d;
  animation-name: fold;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.4, 2.08, 0.55, 0.64);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

.unfold {
  transform-style: preserve-3d;
  animation-name: unfold;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.4, 2.08, 0.55, 0.64);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes fold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}

@keyframes unfold {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.rich-text-content-wrapper ul {
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1.3em;
  padding-left: 1.3em;
}

.rich-text-content-wrapper ol {
  list-style-type: decimal;
  list-style-position: inside;
}

.aiaProfile p {
  color: #24272b;
  margin-top: 2px;
  margin-bottom: 2px;
}

.pulsate-aia-chat {
  animation-name: pulse-animation;
  animation-delay: 2s;
  animation-duration: 2s;
  animation-iteration-count: 3;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(24, 190, 179, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(24, 190, 179, 0);
  }
}
