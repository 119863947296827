.select-input-field button {
  width: 100px;
  height: 35px;
  line-height: 12px;
  top: -2px;
  left: 3px;
  background: #16192f;
  border-color: #16192f;
  color: #fff;
}

.svg-icon svg {
  width: 16px;
  margin: 0 auto;
}
.svg-icon {
  width: 42px;
  text-align: center;
  align-content: center;
  margin-top: -2px !important;
}

.__editor {
  cursor: text;
}

/* @TODO: remove this from here and use tailwind */
.file-select {
  height: 95;
  background: #fff9f7;
  border-color: #ff8060;
}
