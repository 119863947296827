@font-face {
  font-family: "CircularStd";
  src: url("./CircularStd-Book.eot");
  src: url("./CircularStd-Book.eot") format("embedded-opentype"),
    url("./CircularStd-Book.woff2") format("woff2"),
    url("./CircularStd-Book.woff") format("woff"),
    url("./CircularStd-Book.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "CircularStd";
  src: url("./CircularStd-Bold.eot");
  src: url("./CircularStd-Bold.eot?#iefix") format("embedded-opentype"),
    url("./CircularStd-Bold.woff2") format("woff2"),
    url("./CircularStd-Bold.woff") format("woff"),
    url("./CircularStd-Bold.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "font-light";
  src: url("./CircularStd-Black.ttf");
}
.font-light {
  font-family: "font-light";
}
